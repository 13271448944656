<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Home</li>
        <li class="breadcrumb-item"><a href="/setting">Setting</a></li>
      </ol>
    </nav>
    <h1>LOGs</h1>
    <button
      v-if="points.length"
      @click="proccessLogs"
      class="btn btn-primary btn-sm float-end"
      type="button"
    >
      PROCESS
    </button>
    <button
      v-if="points.length && logs.length"
      @click="adsLogs"
      class="btn btn-primary btn-sm float-end me-3"
      type="button"
    >
      ADD LOGs
    </button>
    <form class="row g-3">
      <div v-if="drivers.length" class="col-auto">
        <select
          v-model="driver_id"
          class="form-select"
          aria-label="Default select example"
        >
          <option
            v-for="item in drivers"
            :key="item.HOSDriverId"
            :value="item.HOSDriverId"
          >
            {{ item.Name }} {{ item.LastName }}
          </option>
        </select>
      </div>
      <div class="col-auto">
        <Datepicker
          :class="'form-control'"
          v-model="datesearch"
          :format="customFormatter"
        />
      </div>
      <div class="col-auto">
        <button @click="loadLogsAll" type="button" class="btn btn-primary mb-3">
          Execute
        </button>
      </div>
    </form>
    <hr />
    <div v-if="data_driver" class="table-responsive text-start mb-5">
      <table class="table table-sm table-striped">
        <tbody>
          <tr>
            <th scope="row">Carrier Name</th>
            <th scope="row">Driver Name</th>
            <th scope="row">Truck Tractor ID</th>
            <th scope="row">Start-End Odometer - Distance Today</th>
          </tr>
          <tr>
            <td>{{ data_driver.CarrierName }}</td>
            <td>{{ data_driver.DriverName }}</td>
            <td>{{ data_driver.TractorNumber }}</td>
            <td>
              (<b>{{ data_driver.StartOdometer }}</b
              >) - (<b>{{ data_driver.EndOdometer }}</b
              >) - (<b>{{ data_driver.DistanceTraveled }}</b
              >)
            </td>
          </tr>
          <tr>
            <th scope="row">Carrier Address</th>
            <th scope="row">Driver License</th>
            <th scope="row">Truck Tractor VIN</th>
            <th scope="row">Off Duty - Sleeper Berth</th>
          </tr>
          <tr>
            <td>
              <small>{{ data_driver.CarrierAddress }}</small>
            </td>
            <td>{{ data_driver.DriverLicense }}</td>
            <td>{{ data_driver.TractorVIN }}</td>
            <td>
              {{ toHM(data_driver.OffDutyAmount) }} -
              {{ toHM(data_driver.SleeperBerthAmount) }}
            </td>
          </tr>
          <tr>
            <th scope="row">Home Base Address</th>
            <th scope="row">Driver License State</th>
            <th scope="row">Trailer ID</th>
            <th scope="row">On Duty</th>
          </tr>
          <tr>
            <td>
              <small>{{ data_driver.HomeBaseAddress }}</small>
            </td>
            <td>{{ data_driver.DriverLicenseState }}</td>
            <td>{{ data_driver.TrailerNumber }}</td>
            <td>{{ toHM(data_driver.OnDutyAmount) }}</td>
          </tr>
          <tr>
            <th scope="row">Ruleset</th>
            <th scope="row">Driver ID</th>
            <th scope="row">Trailer Plate</th>
            <th scope="row">Driving</th>
          </tr>
          <tr>
            <td>{{ data_driver.RuleSetId }}</td>
            <td>{{ data_driver.DriverUserName }}</td>
            <td>{{ data_driver.TrailerPlate }}</td>
            <td>
              {{ toHM(data_driver.DrivingAmount) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="points.length" class="row">
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table table-lg">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">TIME</th>
                <th scope="col">STATUS</th>
                <th scope="col">LOCATION</th>
                <th scope="col">ODOMETER</th>
                <th scope="col">DISTANCE (MILES)</th>
                <th scope="col">DURATION</th>
                <th scope="col">Remark</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in points" :key="index">
                <tr
                  v-if="item.HOSDriverName"
                  :class="
                    item.OriginalDutyStatus == 'D'
                      ? 'table-danger'
                      : item.OriginalDutyStatus == 'ON'
                      ? 'table-success'
                      : item.OriginalDutyStatus == 'OFF'
                      ? 'table-dark'
                      : ''
                  "
                >
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ dateUs(item.EpochTimestamp) }}</td>
                  <td>{{ item.OriginalDutyStatus }}</td>
                  <td>{{ item.Location }}</td>
                  <td>{{ item.Odometer }}</td>
                  <td>{{ item.DrivenMiles > 0 ? item.DrivenMiles : "" }}</td>
                  <td>{{ item.EventDuration }}</td>
                  <td>{{ item.Remark }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid text-center mt-5">
    <hr />
    <div v-if="logs.length" class="container">
      <GChart :data="chartData" :options="chartOptions" :type="'BarChart'" />
    </div>
    <p>
      <button
        v-if="logs.length"
        @click="trashAllLogs"
        class="btn btn-danger btn-sm float-end"
        type="button"
      >
        Clear all
      </button>
    </p>
    <br />
    <div class="row my-5">
      <div class="col-md-12">
        <div v-if="logs.length" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">DATE</th>
                <th scope="col">STATE</th>
                <th scope="col">LOCATION</th>
                <th scope="col">ODOM</th>
                <th scope="col">MILES</th>
                <th scope="col">TIME</th>
                <th scope="col">F</th>
                <th scope="col">REMARK</th>
                <th scope="col">UPDATED</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in logs" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <b>{{ item.dateNew }}</b>
                </td>
                <td
                  :class="
                    item.state == 'D'
                      ? 'table-danger'
                      : item.state == 'ON'
                      ? 'table-success'
                      : item.state == 'OFF'
                      ? 'table-dark'
                      : ''
                  "
                >
                  {{ item.state }}
                </td>
                <td>{{ item.location }}</td>
                <td>{{ formatNumber(item.odom) }}</td>
                <td>{{ item.miles > 0 ? item.miles : "" }}</td>
                <td>{{ item.time }}</td>
                <td>{{ logTimeFormat(item.time) }}</td>
                <td>{{ item.remark }}</td>
                <td>{{ item.eventup }}</td>
                <td>
                  <button
                    @click="remarkItemLog(index)"
                    type="button"
                    class="btn btn-warning btn-sm me-3"
                  >
                    Rmk
                  </button>
                  <button
                    @click="getItemLog(index)"
                    type="button"
                    class="btn btn-warning btn-sm me-3"
                  >
                    @
                  </button>
                  <button
                    @click="deleteItemLog(index)"
                    type="button"
                    class="btn btn-danger btn-sm"
                  >
                    X
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr v-if="logs.length" />
        <div v-if="logs.length" class="container">
          <div class="card text-bg-dark">
            <div class="card-body">
              <div class="w-100">
                <div class="w-100 row">
                  <div class="col-sm-6 mb-3">
                    <div class="row">
                      <div class="col-sm-6 mb-3">
                        <label for="xloghours" class="form-label">Hours</label>
                        <input
                          v-model="xloghours"
                          type="number"
                          min="0"
                          class="form-control"
                          id="xloghours"
                        />
                        <div class="d-grid gap-2 mt-4">
                          <button
                            @click="updateDateLog('plus')"
                            class="btn btn-success btn-sm"
                            type="button"
                          >
                            + SUM
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-6 mb-3">
                        <label for="xlogmint" class="form-label"
                          >Minutes <span class="text-danger">*</span></label
                        >
                        <input
                          v-model="xlogmint"
                          type="number"
                          min="0"
                          class="form-control"
                          id="xlogmint"
                        />
                        <div class="d-grid gap-2 mt-4">
                          <button
                            @click="updateDateLog('less')"
                            class="btn btn-danger btn-sm"
                            type="button"
                          >
                            - REST
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-3">
                    <label for="logupdatetime" class="form-label"
                      >TIME {{ xindexlog }}</label
                    >
                    <input
                      v-model="logupdatetime"
                      type="text"
                      class="form-control"
                      id="logupdatetime"
                    />
                    <div class="d-grid gap-2 mt-4">
                      <button
                        @click="updateTimeLog"
                        class="btn btn-secondary btn-sm"
                        type="button"
                        :disabled="!xindexlog"
                      >
                        UPDATE
                      </button>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="d-grid gap-2">
                          <button
                            @click="optLogOne"
                            class="btn btn-info btn-sm"
                            type="button"
                          >
                            OPT1
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="d-grid gap-2">
                          <button
                            @click="updateTimeLog"
                            class="btn btn-primary btn-sm"
                            type="button"
                          >
                            OPT2
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="w-100">
                <div class="w-100 row">
                  <div class="col-sm-3 mb-3">
                    <p>
                      OFF:
                      <span class="badge text-bg-info">{{
                        formatMinutes(xtotal_off)
                      }}</span>
                    </p>
                  </div>
                  <div class="col-sm-3 mb-3">
                    <p>
                      ON:
                      <span class="badge text-bg-secondary">{{
                        formatMinutes(xtotal_on)
                      }}</span>
                    </p>
                  </div>
                  <div class="col-sm-3 mb-3">
                    <p>
                      DRIVE:
                      <span class="badge text-bg-warning">{{
                        formatMinutes(xtotal_drive)
                      }}</span>
                    </p>
                  </div>
                  <div class="col-sm-3 mb-3">
                    <p>
                      SPEED:
                      <span class="badge text-bg-primary">{{
                        speedLogs()
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="modalEle"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Update REMARK
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <input
              v-model="logupdateremark"
              type="text"
              class="form-control"
              id="logupdateremark"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" @click="saveRemark" class="btn btn-primary">
              Save remark
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { onMounted, ref } from "vue";
import { GChart } from "vue-google-charts";
import Datepicker from "vue3-datepicker";
import { Modal } from "bootstrap";

export default {
  name: "HomeView",
  components: {
    GChart,
    Datepicker,
  },
  setup() {
    //CALC DATE
    const data_driver = ref();
    const default_driver = ref();
    const default_dateva = ref();
    const drivers = ref([]);
    const points = ref([]);
    const driver_id = ref();
    const datesearch = ref();
    const dateslist = ref([]);
    const state = ref("ON");
    const date = ref("");
    const hours = ref("");
    const srhours = ref("");
    const mint = ref("");
    const srmint = ref("");
    const resp_mint = ref("");
    const total_on = ref(0);
    const total_off = ref(0);
    const total_drive = ref(0);
    //LOGs
    const logs = ref([]);
    const logs_data = ref("");
    const xindexlog = ref();
    const xindexlogrmk = ref();
    const logupdatedate = ref("");
    const logupdatetime = ref("");
    const logupdateidevent = ref();
    const logupdateremark = ref("");
    let modalEle = ref(null);
    let thisModalObj = null;
    const xtotal_on = ref(0);
    const xtotal_off = ref(0);
    const xtotal_drive = ref(0);
    const xtotal_onh = ref(0);
    const xtotal_driveh = ref(0);
    const xodom1 = ref(0);
    const xodom2 = ref(0);
    const xodomtx = ref(0);
    const xloghours = ref("");
    const xlogmint = ref("");
    const chartData = ref([
      ["City", "Limit", "Hours"],
      ["OnDuty", 14, 0],
      ["Driving", 11, 0],
    ]);
    const chartOptions = ref({
      chart: {
        title: "Spartan Eagle TimeLogs",
        chartArea: { width: "90%" },
        hAxis: {
          title: "Spartan Eagle TimeLogs",
          minValue: 0,
        },
        vAxis: {
          title: "City",
        },
      },
    });

    onMounted(() => {
      thisModalObj = new Modal(modalEle.value);
      const xdrivers = localStorage.getItem("xdrivers_fsc");
      drivers.value = xdrivers ? JSON.parse(xdrivers) : "";
      if (drivers.value) {
        drivers.value = drivers.value.filter(
          (item, iter) => item.IsActive == 1
        );
        driver_id.value = drivers.value[0].HOSDriverId;
      }
      //
      const xdefault_driver = localStorage.getItem("xdefault_driver_fsc");
      default_driver.value = xdefault_driver ? JSON.parse(xdefault_driver) : "";
      if (default_driver.value) {
        driver_id.value = default_driver.value;
      }
      const xdefault_dateva = localStorage.getItem("xdefault_dateva_fsc");
      default_dateva.value = xdefault_dateva ? JSON.parse(xdefault_dateva) : "";
      if (default_dateva.value) {
        datesearch.value = new Date(default_dateva.value);
      }
      const xdata_driver = localStorage.getItem("xdata_driver_fsc");
      data_driver.value = xdata_driver ? JSON.parse(xdata_driver) : "";
      //-------------------------------------------------------------------
      const xpoints = localStorage.getItem("xpoints_fsc");
      points.value = xpoints ? JSON.parse(xpoints) : "";
      if (points.value) {
        //points.value = points.value.filter((item, iter) => iter != 0);
        points.value = points.value.filter(
          (item, iter) => item.HOSDriverName && item.Location
        );
      }
      const xtemplogs = localStorage.getItem("logs_fsc");
      logs_data.value = xtemplogs ? JSON.parse(xtemplogs) : "";
      const xtmlogs = localStorage.getItem("xlogs_fsc");
      logs.value = xtmlogs ? JSON.parse(xtmlogs) : "";
      calcXTimes();
    });

    //FUNCTIONS
    const formatDate = (v) => {
      const d = new Date(v);
      return (
        [
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
          d.getFullYear().padLeft(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getUTCMinutes().padLeft(),
          d.getUTCSeconds().padLeft(),
        ].join(":")
      );
    };
    const inpformatDate = (v) => {
      const d = new Date(v);
      return (
        [
          (d.getMonth() + 1).padLeft(),
          d.getFullYear().padLeft(),
          d.getDate().padLeft(),
        ].join("-") +
        "T" +
        [d.getHours().padLeft(), d.getUTCMinutes().padLeft()].join(":")
      );
    };
    Number.prototype.padLeft = function (base, chr) {
      var len = String(base || 10).length - String(this).length + 1;
      return len > 0 ? new Array(len).join(chr || "0") + this : this;
    };

    const deleteItemDate = (index) => {
      dateslist.value = dateslist.value.filter((item, iter) => iter != index);
      localStorage.setItem("dates_fsc", JSON.stringify(dateslist.value));
      calcTimes();
    };
    const formatMinutes = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return hours > 0 ? hours + "h " + minutes + "min" : minutes + "min";
    };
    const formatNumber = (value) => {
      const aux = new Intl.NumberFormat("en-us").format(value);
      return aux;
    };
    const calcMilles = () => {
      if (milles_init.value && milles_sum.value) {
        milles_end.value = formatNumber(milles_init.value + milles_sum.value);
        addMillesObject(
          formatNumber(milles_init.value),
          formatNumber(milles_sum.value),
          milles_end.value
        );
      } else {
        alert("Complete los campos");
      }
    };
    const trashDates = () => {
      dateslist.value = [];
      localStorage.setItem("dates_fsc", JSON.stringify(dateslist.value));
    };
    const proccessLogs = () => {
      loadLogs();
      calcXTimes();
      localStorage.setItem("logs_fsc", JSON.stringify(logs_data.value));
      localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
    };
    const adsLogs = () => {
      addJoinLogs();
      calcXTimes();
      localStorage.setItem("logs_fsc", JSON.stringify(logs_data.value));
      localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
    };

    const updateXLogs = () => {
      //here
      let old_date = "";
      let old_hours = 0;
      let old_minutes = 0;
      const outlogs = [];
      logs.value.forEach((item) => {
        const xdate = item.date;
        let sum = old_minutes;
        if (old_hours > 0) {
          sum = old_minutes + old_hours * 60;
        }
        let xDateNew = xdate;
        if (sum > 0) {
          const oldInit = new Date(old_date);
          const newDateObj = moment(oldInit).add(sum, "minutes").toDate();
          xDateNew = formatDate(newDateObj);
          xDateNew = formatDate(xDateNew);
        }
        old_hours = item.hours;
        old_minutes = item.minutes;
        old_date = xDateNew;
        outlogs.push({
          length: item.length,
          date: xdate,
          dateNew: xDateNew,
          state: item.state,
          location: item.location,
          odom: item.odom,
          time: item.time,
          hours: item.hours,
          minutes: item.minutes,
          miles: item.miles,
          remark: item.remark,
          driver: item.driver,
          timef: item.timef,
          hoseventid: item.hoseventid,
          eventup: item.eventup,
        });
      });
      logs.value = outlogs;
    };
    const logTimeH = (v) => {
      const x = parseInt(v.split(":")[0]);
      return x;
    };
    const logTimeMin = (v) => {
      const x = parseInt(v.split(":")[1]);
      return x;
    };
    const logTimeFormat = (v) => {
      const time = v.split(":");
      const h = parseInt(time[0]);
      const m = parseInt(time[1]);
      return h + "h " + m + "m";
    };
    const remarkItemLog = (index) => {
      xindexlogrmk.value = index;
      const local_log = logs.value[index];
      logupdateremark.value = local_log.remark;
      logupdateidevent.value = local_log.hoseventid;
      xindexlog.value = null;
      logupdatedate.value = null;
      logupdatetime.value = null;
      thisModalObj.show();
      //here
    };
    const saveRemark = async () => {
      //here
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        HOSEventId: logupdateidevent.value,
        Remark: logupdateremark.value,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(
        "https://livetrack.atcompass.net/ws/WSHOS.asmx/AddRemark",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          //here updated
          const index = xindexlogrmk.value;
          logs.value[index].remark = logupdateremark.value;
          localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
          thisModalObj.hide();
        })
        .catch((error) => console.error(error));
    };
    const getItemLog = (index) => {
      xindexlog.value = index + 1;
      const local_log = logs.value[index];
      logupdatedate.value = inpformatDate(local_log.dateNew);
      logupdatetime.value = local_log.time;
    };
    const updateDateLog = (action) => {
      //here
      if (xlogmint.value) {
        let sum = xlogmint.value;
        if (xloghours.value) {
          sum = xlogmint.value + xloghours.value * 60;
        }
        console.log("datwe", logs.value[0].date);
        const oldInit = new Date(logs.value[0].date);
        if (action == "plus") {
          const newDateObj = moment(oldInit).add(sum, "minutes").toDate();
          const newInit = formatDate(newDateObj);
          logs.value[0].date = newInit;
        } else {
          const newDateObj = moment(oldInit).subtract(sum, "minutes").toDate();
          const newInit = formatDate(newDateObj);
          logs.value[0].date = newInit;
        }
        localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
        updateXLogs();
        calcXTimes();
      }
    };
    const updateTimeLog = () => {
      const index = xindexlog.value - 1;
      const hours = logTimeH(logupdatetime.value);
      const minutes = logTimeMin(logupdatetime.value);
      logs.value[index].time = logupdatetime.value;
      logs.value[index].hours = hours;
      logs.value[index].minutes = minutes;
      localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
      updateXLogs();
      calcXTimes();
    };
    const deleteItemLog = (index) => {
      logs.value = logs.value.filter((item, iter) => iter != index);
      localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
      updateXLogs();
      calcXTimes();
    };
    const calcXTimes = () => {
      if (logs.value.length) {
        let off = 0;
        let on = 0;
        let drive = 0;
        logs.value.forEach((item) => {
          let time = item.minutes;
          if (item.hours) {
            time = item.minutes + item.hours * 60;
          }
          if (item.state == "OFF") {
            off += time;
          } else if (item.state == "ON") {
            on += time;
          } else if (item.state == "D") {
            drive += time;
          }
        });
        xtotal_off.value = off;
        xtotal_on.value = on;
        xtotal_drive.value = drive;
        const min_auxl = xtotal_drive.value > 0 ? xtotal_drive.value / 60 : 0;
        xtotal_driveh.value =
          Math.round((min_auxl + Number.EPSILON) * 100) / 100;
        chartData.value[2][2] = xtotal_driveh.value;
        const mint_auxl = xtotal_on.value > 0 ? xtotal_on.value / 60 : 0;
        xtotal_onh.value = Math.round((mint_auxl + Number.EPSILON) * 100) / 100;
        chartData.value[1][2] = mint_auxl + xtotal_driveh.value;
        xodom1.value = 0;
        xodom2.value = 0;
        if (logs.value.length > 2) {
          xodom1.value = logs.value[0].odom;
          xodom2.value = logs.value[logs.value.length - 1].odom;
          xodomtx.value = xodom2.value - xodom1.value;
        }
      }
    };
    const trashAllLogs = () => {
      logs_data.value = "";
      logs.value = [];
      localStorage.setItem("logs_fsc", JSON.stringify(logs_data.value));
      localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
    };
    const speedLogs = () => {
      const x = xodomtx.value > 0 ? xodomtx.value / xtotal_driveh.value : 0;
      return Math.round((x + Number.EPSILON) * 100) / 100;
    };
    const customFormatter = (date) => {
      return moment(date).format("M-D-YYYY");
    };
    const dateUs = (v) => {
      const dt = moment.unix(v).format("MM/DD/YYYY HH:mm:ss");
      return formatDate(dt);
      //return moment.unix(v).format("MM/DD/YYYY HH:mm:ss");
    };
    const loadLogsAll = async () => {
      //here
      default_driver.value = driver_id.value;
      localStorage.setItem(
        "xdefault_driver_fsc",
        JSON.stringify(driver_id.value)
      );
      default_dateva.value = datesearch.value;
      localStorage.setItem(
        "xdefault_dateva_fsc",
        JSON.stringify(datesearch.value)
      );
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        HOSClientId: 27652,
        HOSDriverId: driver_id.value,
        StartTime: datesearch.value,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(
        "https://livetrack.atcompass.net/ws/WSHOS.asmx/GetLogbookData",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const o_all = result ? JSON.parse(result) : [];
          console.log(o_all.d, o_all.d.Points);
          points.value = o_all.d.Points;
          if (points.value) {
            points.value = points.value.filter(
              (item, iter) => item.HOSDriverName && item.Location
            );
          }
          localStorage.setItem("xpoints_fsc", JSON.stringify(points.value));
          data_driver.value = o_all.d;
          localStorage.setItem(
            "xdata_driver_fsc",
            JSON.stringify(data_driver.value)
          );
        })
        .catch((error) => console.error(error));
    };
    const loadLogs = () => {
      let old_date = "";
      let old_hours = 0;
      let old_minutes = 0;
      let cont = 0;
      const outlogs = [];
      points.value.forEach((item) => {
        cont++;
        if (item.HOSDriverName) {
          const xdate = dateUs(item.EpochTimestamp); //
          const time = item.EventDuration;
          const hours = logTimeH(time);
          const minutes = logTimeMin(time);
          let sum = old_minutes;
          if (old_hours > 0) {
            sum = old_minutes + old_hours * 60;
          }
          let xDateNew = xdate;
          if (sum > 0) {
            const oldInit = new Date(old_date);
            const newDateObj = moment(oldInit).add(sum, "minutes").toDate();
            xDateNew = formatDate(newDateObj);
            xDateNew = formatDate(xDateNew);
          }
          old_hours = hours;
          old_minutes = minutes;
          old_date = xDateNew;
          outlogs.push({
            length: cont,
            date: xdate,
            dateNew: xDateNew,
            state: item.OriginalDutyStatus,
            location: item.Location,
            odom: item.Odometer,
            time: item.EventDuration,
            hours: hours,
            minutes: minutes,
            miles: item.DrivenMiles,
            remark: item.Remark,
            driver: item.HOSDriverName,
            timef: logTimeFormat(time),
            hoseventid: item.HOSEventId,
            eventup: "",
          });
        }
      });
      logs.value = outlogs;
    };
    const addJoinLogs = () => {
      let old_date = "";
      let old_hours = 0;
      let old_minutes = 0;
      let cont = 0;
      points.value.forEach((item) => {
        cont++;
        if (item.HOSDriverName) {
          const xdate = dateUs(item.EpochTimestamp); //
          const time = item.EventDuration;
          const hours = logTimeH(time);
          const minutes = logTimeMin(time);
          let sum = old_minutes;
          if (old_hours > 0) {
            sum = old_minutes + old_hours * 60;
          }
          let xDateNew = xdate;
          if (sum > 0) {
            const oldInit = new Date(old_date);
            const newDateObj = moment(oldInit).add(sum, "minutes").toDate();
            xDateNew = formatDate(newDateObj);
            xDateNew = formatDate(xDateNew);
          }
          old_hours = hours;
          old_minutes = minutes;
          old_date = xDateNew;
          logs.value.push({
            length: cont,
            date: xdate,
            dateNew: xDateNew,
            state: item.OriginalDutyStatus,
            location: item.Location,
            odom: item.Odometer,
            time: item.EventDuration,
            hours: hours,
            minutes: minutes,
            miles: item.DrivenMiles,
            remark: item.Remark,
            driver: item.HOSDriverName,
            timef: logTimeFormat(time),
            hoseventid: item.HOSEventId,
            eventup: "",
          });
        }
      });
    };
    const toHM = (totalSeconds) => {
      const totalMinutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      let dttime = "";
      if (hours) {
        dttime += hours + "h";
      }
      if (minutes) {
        if (dttime) {
          dttime += " ";
        }
        dttime += minutes + "m";
      }
      /*if (seconds) {
        if (dttime) {
          dttime += " ";
        }
        dttime += seconds + "s";
      }*/
      return dttime;
    };
    const optLogOne = () => {
      //here
      let old_date = "";
      let old_hours = 0;
      let old_minutes = 0;
      let driver_minutes = 0;
      const outlogs = [];
      logs.value.forEach((item, index) => {
        let eventup = "";
        let timetx = item.minutes;
        if (item.hours > 0) {
          timetx = item.minutes + item.hours * 60;
        }
        //ajustar los ON
        if (item.state == "ON") {
          //here
          if (index == 0 && timetx > 15) {
            //here
            timetx = 15;
            eventup = "YES";
          } else if (index > 0 && timetx > 18) {
            timetx = Math.floor(Math.random() * (18 - 4)) + 4;
            eventup = "YES";
          }
        } else if (item.state == "OFF") {
          //here
          const next_driver = nextDriverLog(index);
          const sum_driver = driver_minutes + next_driver;
          if (timetx > 5 && timetx < 600) {
            timetx = Math.floor(Math.random() * (11 - 3)) + 3;
            //si supera las 8 horas, entonces break de 30 min
            if (sum_driver >= 480) {
              timetx = Math.floor(Math.random() * (36 - 30)) + 30;
            }
            eventup = "YES";
          }
        } else if (item.state == "D") {
          driver_minutes += timetx;
        }
        const xdate = item.date;
        let sum = old_minutes;
        if (old_hours > 0) {
          sum = old_minutes + old_hours * 60;
        }
        let xDateNew = xdate;
        if (sum > 0) {
          const oldInit = new Date(old_date);
          const newDateObj = moment(oldInit).add(sum, "minutes").toDate();
          xDateNew = formatDate(newDateObj);
          xDateNew = formatDate(xDateNew);
        }
        const hours_aux = Math.floor(timetx / 60);
        const min_aux = timetx % 60;
        old_hours = hours_aux;
        old_minutes = min_aux;
        old_date = xDateNew;
        const x_time = item.time.split(":");
        const parseh = hours_aux < 10 ? "0" + hours_aux : hours_aux;
        const parsem = min_aux < 10 ? "0" + min_aux : min_aux;
        const x_timeend = parseh + ":" + parsem + ":" + x_time[2];
        outlogs.push({
          length: item.length,
          date: xdate,
          dateNew: xDateNew,
          state: item.state,
          location: item.location,
          odom: item.odom,
          time: x_timeend,
          hours: hours_aux,
          minutes: min_aux,
          miles: item.miles,
          remark: item.remark,
          driver: item.driver,
          timef: item.timef,
          hoseventid: item.hoseventid,
          eventup: eventup,
        });
      });
      logs.value = outlogs;
      localStorage.setItem("xlogs_fsc", JSON.stringify(logs.value));
      updateXLogs();
      calcXTimes();
    };
    const nextDriverLog = (index) => {
      //here
      let timetotal = 0;
      const outlogs = logs.value.filter(
        (item, iter) => iter > index && item.state == "D"
      );
      if (outlogs.length) {
        const item = outlogs[0];
        timetotal = item.minutes;
        if (item.hours > 0) {
          timetotal = item.minutes + item.hours * 60;
        }
      }
      return timetotal;
    };
    return {
      saveRemark,
      modalEle,
      toHM,
      dateUs,
      points,
      loadLogsAll,
      customFormatter,
      dateslist,
      state,
      date,
      hours,
      srhours,
      mint,
      srmint,
      resp_mint,
      trashDates,
      calcMilles,
      total_on,
      total_off,
      total_drive,
      formatMinutes,
      deleteItemDate,
      proccessLogs,
      adsLogs,
      logs_data,
      logs,
      remarkItemLog,
      getItemLog,
      deleteItemLog,
      logTimeFormat,
      formatNumber,
      xindexlog,
      xindexlogrmk,
      logupdatedate,
      logupdatetime,
      logupdateremark,
      updateDateLog,
      updateTimeLog,
      trashAllLogs,
      xloghours,
      xlogmint,
      xtotal_on,
      xtotal_off,
      xtotal_drive,
      chartData,
      chartOptions,
      xtotal_driveh,
      xtotal_onh,
      xodom1,
      xodom2,
      xodomtx,
      speedLogs,
      data_driver,
      drivers,
      driver_id,
      datesearch,
      formatDate,
      optLogOne,
    };
  },
};
</script>
